import './NavItem.css';

import React, { useState } from 'react';

const NavItem = (props) => {

    const [open, setOpen ] = useState(false);

    return (
        <div className={props.css}>
            <button className={props.button} onClick={() => setOpen(!open)}>
                {props.icon}
            </button>
            { open && props.children}
        </div>
    );

}

export default NavItem