import logo from './logo.svg';

import NavBar from './components/NavBar/NavBar.js';
import './App.css';

import React, { useState } from 'react';
import SlideBody from './components/SlideBody/SlideBody';
import Mission from './components/Mission/Mission';
import MyRate from './components/MyRate/MyRate';
import Separators from './components/Seperators/Separators';
import Resume from './components/Resume/Resume';

const  App = () => {
  return (
    <div className='App'>
      <NavBar />
      <SlideBody />
      <Separators color='black' />
      <Mission />
      <Separators color='gray' />
      <Resume />
      <Separators color='green' />
      <MyRate />
      <Separators color='black' />
    </div>
  );
}

export default App;