import './DropDownMenu.css';

const DropDownMenu = (props) => {

    return (
        <div className={props.name}>
            {props.list}
        </div>
    );
}

export default DropDownMenu;