import './Mission.css';

const Mission = () => {

    return (
        <div className='Mission'>
            <div className='MissionBoard'>
                Together we work to expand our world...
            </div>
            <div className='MissionExplainedBoard'>
                <div className='MissionImage1' />
                <div className='MissionExplained'>
                    In all honesty, I am constantly improving my skills in the realm of web development. There's always something new for me to learn, practice, and efficiently code the web. Let me work for you as you work for me.
                </div>
            </div>
        </div>
    );
}

export default Mission;