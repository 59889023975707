import './SlideBody.css';

const SlideBody = () => {

    return (
        <div className='SlideBody'>
            <div className='Hook'>
                Your Deaf Web Developer
            </div>
            <div className='IntroMessage'>
                <div>
                    Need a webpage?
                </div>
                <div>
                    Update your webpage?
                </div>
                <div>
                    Revamp older webpage?
                </div>
                <div>
                    This is the right place!
                </div>
            </div>
            <div className='ExploreMore'>
                <button>
                    Show me more!
                </button>
            </div>
        </div>
    );
}

export default SlideBody;