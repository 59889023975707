import './Separators.css';

const Separators = (props) => {

    const color = props.color;

    return (
        <div className={color} />
    );
}

export default Separators;