import './MenuList.css';

const MenuList = () => {

    const gotoThat = () => {

    }

    return (
        <div className='MenuList'>
            <div className='MenuOptions'>
                <div>
                    Mission
                </div>
                <div>
                    Projects
                </div>
                <div>
                    Resume
                </div>
                <div>
                    Thoughts
                </div>
                <div className='About'>
                    About
                </div>
            </div>
        </div>
    );
}

export default MenuList;