import './MyRate.css';

const MyRate = () => {

    return (
        <div className='MyRate'>
            My Rates don't scare you...
            <div className='Inexpensive'>
                <div>
                    The basics...
                </div>
            </div>
            <div className='Expensive'>
                <div>
                    The Advanceds..
                </div>
            </div>
        </div>
    );
}

export default MyRate;