import './NavBar.css';

import { ReactComponent as BellIcon } from './../../icons/bell.svg';
import { ReactComponent as MessengerIcon } from './../../icons/messenger.svg';
import { ReactComponent as CaretIcon } from './../../icons/caret.svg';
import { ReactComponent as PlusIcon } from './../../icons/plus.svg';
import { ReactComponent as CogIcon } from './../../icons/cog.svg';
import { ReactComponent as ChevronIcon } from './../../icons/chevron.svg';
import { ReactComponent as ArrowIcon } from './../../icons/arrow.svg';
import { ReactComponent as BoltIcon } from './../../icons/bolt.svg';

import React, { useState } from 'react';

import NavItem from './NavItem/NavItem.js';
import DropDownMenu from './DropDownMenu/DropDownMenu.js';
import MenuList from './MenuList/MenuList.js';
import Signature from './Signature/Signature.js';
import SettingList from './SettingList/SettingList';


const NavBar = () => { 

    return (
        <div className='NavBar'>
            <NavItem css='NavItem' button='NavButton' icon={<CogIcon />}>
                <DropDownMenu css='DropDownMenu' name='settings' list={<SettingList />} />
            </NavItem>

            <Signature name='Ryan Modica'/>

            <NavItem css='NavItem' button='NavButton' icon={<CaretIcon />}>
                <DropDownMenu css='DropDownMenu' name='menu' list={<MenuList />}/>
            </NavItem>
        </div>
    );
}

export default NavBar;