import './Resume.css';

const Resume = () => {

    return (
        <div className='Resume'>
            <div className='ResumeExplained'>
               A Resume for you to see where I'm from...
            </div>
            <div className='ResumeHistory'>
                <div className='MyName'>
                    Ryan Modica
                </div>
                <div className='MyJob'>
                    Full-stack web developer
                </div>
                <div className='MyEducation'>
                    <div className='MyGraduation'>
                        Bachelor of Science in Multidisciplinary Studies with a Concentration in Mathematics and Applied Computing
                        <div>
                            Rochester Insistute of Technology - '11
                        </div>
                    </div>
                    <div className='Graduation' />
                </div>
                <div className='WorkHistoryDetails'>

                </div>
            </div>
        </div>
    );
}

export default Resume;