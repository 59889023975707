import './Signature.css';

const Signature = (props) => {

    return (
        <div className='Signature'>
            {props.name}
        </div>
    );
}

export default Signature;