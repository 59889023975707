import './SettingList.css';

const SettingList = () => {

    return (
        <div>
            <div>
                Login - WIP
            </div>
            <div>
                Register - WIP
            </div>
            <div>
                Setting - WIP
            </div>
        </div>
    );
}

export default SettingList;